
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast.js'
import LocationForm from '@/components/LocationForm.vue'
import { db } from '@/services/firebase'
import { Location } from '@/types.ts'
import { getUTC } from '@/helpers/date.ts'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { LocationForm, BaseBreadcrumb },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const location = ref({})
    const locationId = route.params.id
    const breadcrumbs = [
      { label: 'Locations', to: '/locations' },
      { label: 'Update' }
    ]

    function getLocation() {
      db.ref('locations/' + locationId)
        .once('value')
        .then(snapshot => {
          if (snapshot.val())
            location.value = { ...snapshot.val(), id: locationId }
        })
    }

    function update(formData: Location) {
      const utc = getUTC(new Date())
      db.ref('locations/' + locationId)
        .update({ ...formData, modified: utc })
        .then(() => {
          router.push({ name: 'LocationIndex' })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: err.message,
            life: 3000
          })
        })
    }

    getLocation()

    return { update, location, breadcrumbs }
  }
}
